<template>
  <div>
    <v-app-bar v-if="isAuth" color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="false" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-2" fab dark small color="light-blue" v-bind="attrs" v-on="on">
            <v-badge :content="leidos" :value="leidos" color="pink" overlap>
              <v-icon dark> mdi-bell </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="mx-auto" min-width="500" max-width="600">
          <v-card-text id="card-msg">
            <h4>SIN LEER ({{ leidos }})</h4>
            <v-spacer></v-spacer>
            <v-btn @click="checkall" small fab elevation="0">
              <v-icon center v-bind:style="leidos ? 'color: red' : 'color: green;'"> mdi-email-multiple </v-icon>
            </v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-virtual-scroll :items="notificacion" :item-height="itemheight" height="185">
            <template v-slot:default="{ item }">
              <v-list-item two-line link :class="{ active: item.estado === true }" :key="item.id"
                ><v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item-content
                      @click="toggleClass()"
                      v-on="on"
                      :class="{ 'color-text': item.estado === true }"
                    >
                      <v-list-item-title v-bind:class="[isActive ? 'wrap-text' : '']">
                        {{ item.mensaje }}</v-list-item-title
                      >
                      <v-list-item-subtitle v-bind:class="[isActive ? 'wrap-text' : '']" v-if="item.mensaje2">
                        {{ item.mensaje2 }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <span>{{ item.mensaje }}</span>
                </v-tooltip>
                <v-list-item-action class="icon" id="icon2">
                  <v-btn small fab elevation="0" icon @click="check(item.id)">
                    <v-icon v-if="item.estado" color="green" center> mdi-email-check-outline </v-icon>
                    <v-icon v-else color="red" center> mdi-email-remove-outline </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-menu>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text> {{ user }} <v-icon right>mdi-account</v-icon> </v-btn>
        </template>
        <v-list>
          <v-list-item @click="profile">
            <v-list-item-avatar>
              <img src="../assets/avatar.png" alt="avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="clave">
            <v-list-item-title>Cambiar Clave</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Salir <v-icon style="margin-left: 88px">mdi-exit-to-app</v-icon> </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
          <v-list-item v-for="m in menu" :key="m.icon" :href="m.href">
            <v-list-item-icon>
              <v-icon>{{ m.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ m.nombre }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import { redireccion } from '../common/util';

const axios = require('axios');

export default {
  name: 'Navbar',
  data: () => ({
    drawer: false,
    group: null,
    isAuth: false,
    titulo: 'Mendoza Fiduciaria',
    user: '',
    mensajes: '0',
    rol: '',
    notificacion: [],
    icon: '',
    isActive: false,
    itemheight: 60,
    menu: [
      {
        nombre: 'Dashboard',
        icon: 'mdi-home',
        href: '/',
      },
      {
        nombre: 'Catalogo',
        icon: 'mdi-folder-table-outline',
        href: '/catalogo',
      },
    ],
    nombrePerfil: '',
  }),
  computed: {
    leidos() {
      let count = 0;
      this.notificacion.forEach((element) => {
        if (element.estado === false) {
          count += 1;
        }
      });
      return count;
    },
  },
  methods: {
    toggleClass() {
      this.isActive = !this.isActive;
      if (this.itemheight === 60) {
        this.itemheight = 90;
      } else {
        this.itemheight = 60;
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.clear();
      this.validateAuth();
      this.$router.push('/login');
    },
    clave() {
      this.$router.push('/Pass');
    },
    profile() {
      this.$router.push('/profile').catch(() => {});
    },
    async validateAuth() {
      // const tokenLS = localStorage.getItem('token');
      // let isAuth = false;
      // if (tokenLS !== 'undefined' && tokenLS !== null && tokenLS !== '') {
      //   await axios
      //     .post(`${API_URL}api/usuarios/validarToken`, {
      //       token: tokenLS,
      //     })
      //     .then((response) => {
      //       this.traerNotificaciones();
      //       if (response.data.status === 'success') {
      //         isAuth = true;
      //         this.user = `${localStorage.getItem('usuarioNombre')} ${localStorage.getItem('usuarioApellido')}`;
      //       }
      //     });
      // }
      // this.isAuth = isAuth;
      const isValid = await redireccion();
      if (!isValid) {
        this.isAuth = false;
        localStorage.removeItem('token');
        localStorage.clear();
        this.$router.push('/login');
      } else {
        this.isAuth = true;
        this.user = `${localStorage.getItem('usuarioNombre')} ${localStorage.getItem('usuarioApellido')}`;
      }
    },
    async traerNotificaciones() {
      this.getVencimientos();
      const url = `${API_URL}api/notificaciones/user/${localStorage.getItem('usuario_id')}`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.notificacion = response.data.notificaciones;
            this.mensajes = Object.keys(response.data.notificaciones).length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    check(id) {
      axios
        .post(`${API_URL}api/notificaciones/check`, {
          token: localStorage.getItem('token'),
          notificacion_id: id,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.traerNotificaciones();
          } else if (response.data.status === 'error') {
            console.log('no');
          }
        });
    },
    checkall() {
      axios
        .post(`${API_URL}api/notificaciones/checkall`, {
          token: localStorage.getItem('token'),
          usuario_id: localStorage.getItem('usuario_id'),
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.traerNotificaciones();
          } else if (response.data.status === 'error') {
            console.log('no');
          }
        });
    },
    getVencimientos() {
      const url = `${API_URL}api/documentacionClientes/vencimiento/${localStorage.getItem('cliente_id')}`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            console.log('ok');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.validateAuth();
    this.$bus.$on('login', () => {
      this.validateAuth();
    });
  },
};
</script>
<style>
#card-msg {
  display: flex;
  padding: 5px 20px 5px 32px;
}

#card-msg h4 {
  align-self: center;
}

#icon1 {
  margin: 7px;
}

#icon2 {
  margin: 0px;
}

.active {
  background-color: #e4effa;
}

.color-text {
  color: #1976d2;
}

.wrap-text {
  white-space: normal !important;
}
</style>
